export default function PrivacyPolicy() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="pixel-font">Gizmoat</h1>
      </header>

      <main className="App-main">
        <div className="privacy-container">
          <p className="privacy-text">
            This privacy policy ("policy") will help you understand how
            Experience Holding B.V. (Trading as Gizmoat studios) ("us", "we",
            "our") uses and protects your data when you play Gizmoat ("app ",
            "service"). We reserve the right to change this policy at any given
            time, of which you will be promptly updated. If you want to make
            sure that you are up to date with the latest changes, we advise you
            to frequently visit this page.
          </p>
          <h2 className="privacy-heading">What User Data We Collect</h2>
          <p className="privacy-text">
            We collect no personal data as a result of you downloading Gizmoat
            or playing the game. We do not store your gameplay, game behaviour,
            metrics or personal information at any time. Gizmoat studios stores
            all gameplay progress data locally on your device for the purposes
            of retaining your game progress only. This information is never
            shared with any third party, or transmitted online in any way.
          </p>
          <h2 className="privacy-heading">Unity Ads Data Collection</h2>
          <p className="privacy-text">
            Gizmoat may display advertisements through Unity Ads. Unity Ads may
            collect certain types of data to provide personalized advertising
            experiences and improve ad relevance. The types of data Unity Ads
            may collect include:
            <ul className="privacy-list">
              <li>
                Device information (e.g., device model, operating system, unique
                device identifiers)
              </li>
              <li>IP address (used for approximate location targeting)</li>
              <li>Advertising ID</li>
              <li>App usage data related to ad interactions</li>
            </ul>
            <p className="privacy-text">
              For more details on how Unity Ads collects and processes data,
              please refer to the Unity Privacy Policy:
              <a
                href="https://unity.com/legal/privacy-policy"
                className="privacy-link"
              >
                https://unity.com/legal/privacy-policy.
              </a>
            </p>
          </p>
          <h2 className="privacy-heading">Our Cookie Policy</h2>

          <div className="privacy-section">
            <h3 className="privacy-subheading">Unity Ads</h3>
            <p className="privacy-text">
              We partner with Unity Ads to display advertisements on our
              website. Unity Ads may use cookies and similar technologies to
              collect information about your interactions with these ads and our
              website. This data helps in delivering personalized ads and
              measuring their effectiveness. For more information on how Unity
              Ads collects and uses data, please refer to the Unity Cookie
              Policy.
            </p>
            <h3 className="privacy-subheading">
              Managing your cookie preferences
            </h3>
            <p className="privacy-text">
              Upon your first visit to our website, you will be asked to consent
              to the placement of cookies. You can change your cookie
              preferences or withdraw your consent at any time through your
              browser settings. Please note that disabling certain cookies may
              affect the functionality of our website.
            </p>
            <h3 className="privacy-subheading">Changes to our cookie policy</h3>
            <p className="privacy-text">
              We may update this cookie policy from time to time to reflect
              changes in our practices or relevant legislation. We recommend
              checking this policy regularly for the latest information on our
              use of cookies.
            </p>
          </div>
          <h2 className="privacy-heading">Links to Other Websites</h2>
          <p className="privacy-text">
            Gizmoat studios may contain links to external websites or platforms
            (IE Discord). Experience Holding B.V. is not held responsible for
            your data and privacy protection should you choose to visit
            platforms outside of Gizmoat app. Visiting those websites is not
            governed by this privacy policy agreement. Make sure to read the
            privacy policy documentation of the website you go to from our
            website. Gizmoat studios will not lease, sell or distribute your
            personal information to any third parties for any reason at any
            time. We will also never send you promotional materials of any kind
            as a result of downloading this app or playing this game.
          </p>
        </div>
      </main>

      <footer className="App-footer">
        <a href="/" className="footer-link">
          Home
        </a>
        <span> | </span>
        <a href="/terms-and-conditions" className="footer-link">
          Terms and Conditions
        </a>
      </footer>
    </div>
  );
}
